import React from "react";
import { Link, Navigate } from "react-router-dom";
import { Button } from "../Elements/Button";
import { LinkButton } from "../Elements/LinkButton";
import style from "./TalentImgs.module.scss";
export type TalentImgsProps = { imgs: Array<string> };

const TalentImgs: React.FC<TalentImgsProps> = (props) => {
  return (
    <section className={style.TalentImgs}>
      <h1 className={style.bgTalent}>使用可能素材イメージ</h1>
      <p className={style.notes}>
        タレント写真素材の納品に関しては素材イメージの通り、
        白バックにて撮影したタレント写真素材（レタッチ済み）を納品させていただきます。
        各種制作物の使用における自分物トリミング等の作業は企業様側にてお願いいたします
      </p>
      {props.imgs.length === 0 ? (
        <div className={style.noImage}>
          <h1>準備中</h1>
        </div>
      ) : (
        <div className={style.imgs}>
          {props.imgs.map((img, key) => (
            <img src={img} key={key} className={style.img} />
          ))}
        </div>
      )}

      <div>
        <Link to={"/"} style={{ display: "block" }}>
          <LinkButton fullWidth>一覧に戻る</LinkButton>
        </Link>
      </div>
    </section>
  );
};
TalentImgs.displayName = "TalentImgs";
export { TalentImgs };
