import React from "react";
import { SignUp } from "../component/Form/SignUp";
import "./auth.scss";

export const SignUpPage: React.FC = () => {
  return (
    <div className="authRoot">
      <div className="header">
        <h1>新規登録</h1>
      </div>
      <div className="body">
        <SignUp />
      </div>
    </div>
  );
};
