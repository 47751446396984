import React from "react";
import style from "./SearchBox.module.scss";
export type SearchBoxProps = {
  placeholder: string;
  buttonText: string;
  ButtonClick: () => void;
} & JSX.IntrinsicElements["input"];

const SearchBox: React.FC<SearchBoxProps> = (props) => {
  const { placeholder, buttonText, ButtonClick, ...OtherProps } = props;
  return (
    <div className={style.box}>
      <input
        placeholder={placeholder}
        className={style.SearchBox}
        {...OtherProps}
      />
      <button onClick={() => ButtonClick()}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 15.568 15.546"
          style={{ display: "inline", marginRight: "12px" }}
        >
          <path
            id="magnifying-glass"
            d="M16.161,13.57l-2.63-2.63L11.509,10.1a5.716,5.716,0,1,0-1.38,1.387l.834,2.017,2.63,2.63a1.816,1.816,0,1,0,2.569-2.569Zm-14-6.737a4.67,4.67,0,1,1,4.67,4.67A4.676,4.676,0,0,1,2.163,6.833ZM15.427,15.4a.779.779,0,0,1-1.1,0l-2.484-2.484-.775-1.876,1.876.775L15.427,14.3a.779.779,0,0,1,0,1.1Z"
            transform="translate(-1.125 -1.125)"
            fill="#fff"
          />
        </svg>
        {buttonText}
      </button>
    </div>
  );
};
SearchBox.displayName = "SearchBox";
export { SearchBox };
