import React from "react";

export type IconSliderProps = JSX.IntrinsicElements["svg"];

export const IconSlider: React.FC<IconSliderProps> = (props) => {
  return (
    <svg
      id="sliders"
      xmlns="http://www.w3.org/2000/svg"
      width="32.608"
      height="30.57"
      viewBox="0 0 32.608 30.57"
      {...props}
    >
      <path
        id="パス_1335"
        data-name="パス 1335"
        d="M28.956,7.345A5.095,5.095,0,1,1,23.861,2.25a5.095,5.095,0,0,1,5.095,5.095ZM23.861,10.4A3.057,3.057,0,1,0,20.8,7.345,3.057,3.057,0,0,0,23.861,10.4ZM14.69,17.535A5.095,5.095,0,1,1,9.595,12.44,5.095,5.095,0,0,1,14.69,17.535ZM9.595,20.592a3.057,3.057,0,1,0-3.057-3.057A3.057,3.057,0,0,0,9.595,20.592Zm19.361,7.133a5.095,5.095,0,1,1-5.095-5.095A5.095,5.095,0,0,1,28.956,27.725Zm-5.095,3.057A3.057,3.057,0,1,0,20.8,27.725,3.057,3.057,0,0,0,23.861,30.782Z"
        transform="translate(-0.424 -2.25)"
        fill="#707070"
        fillRule="evenodd"
      />
      <path
        id="パス_1336"
        data-name="パス 1336"
        d="M19.361,8.788H0V6.75H19.361Zm13.247,0H27.513V6.75h5.095ZM19.361,29.168H0V27.13H19.361Zm13.247,0H27.513V27.13h5.095ZM13.247,18.978H32.608V16.94H13.247ZM0,18.978H5.095V16.94H0Z"
        transform="translate(0 -2.674)"
        fill="#707070"
        fillRule="evenodd"
      />
    </svg>
  );
};
