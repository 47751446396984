import React from "react";
import { Oval } from "react-loader-spinner";
import "../../style/loading.scss";
function Loading() {
  return (
    <div className="loading">
      <Oval
        height={80}
        width={80}
        color="#2182C3"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#E9F4FB"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );
}

export default Loading;
