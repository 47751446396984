import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { Button } from "../Elements/Button";
import { Input } from "../Elements/Input";
import { Select } from "../Elements/Select";
import "./auth.scss";
import { Link } from "react-router-dom";
import { ConfirmCodeForm } from "./ConfirmCodeForm";

type FormData = {
  email: string;
  password: string;
  /*会社名*/
  company: string;
  /* 担当者めい(かんじ)*/
  name: string;
  /* 担当者名のフリガナ */
  yomi: string;
  /* 電話番号*/
  phone: string;
  /* 業務形態*/
  business: string;
  /*部署名*/
  department: string;
  /* 役職名 */
  position: string;
  /* url */
  url: string;
  /* 登録目的 */
  purpose: string;
};

export const SignUp: React.FC = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [isConfirm, setIsConfirm] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const onSubmit = handleSubmit(async (data) => {
    const reg = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[!-~]{8,100}/;
    if (
      data.email.length !== 0 &&
      data.password.length !== 0 &&
      data.company.length !== 0 &&
      data.name.length !== 0 &&
      data.yomi.length !== 0 &&
      data.phone.length !== 0 &&
      data.business.length !== 0 &&
      data.position.length !== 0 &&
      data.url.length !== 0 &&
      data.purpose.length !== 0 &&
      reg.test(data.password)
    ) {
      const result = await auth.signup(
        data.email,
        data.password,
        data.company,
        data.name,
        data.phone,
        data.business,
        data.department,
        data.position,
        data.yomi,
        data.url,
        data.purpose
      );
      if (result.status) {
        setEmail(data.email);
        setPassword(data.password);
        setIsConfirm(true);
        //navigate("/signin?signup=true");
      } else {
        if (result.error.name === "UsernameExistsException") {
          toast.error("既に登録されているメールアドレスです");
        } else if (result.error.name === "InvalidPasswordException") {
          toast.error(
            "大文字・小文字・数字すべて使用した8文字以上のパスワードを設定してください"
          );
        } else {
          toast.error("エラーが発生しました");
          toast.error(result.error.name);
        }
      }
      console.log(result);
      console.log({ data });
    } else {
      if (
        data.email.length === 0 ||
        data.password.length === 0 ||
        data.company.length === 0 ||
        data.name.length === 0 ||
        data.yomi.length === 0 ||
        data.phone.length === 0 ||
        data.business.length === 0 ||
        data.position.length === 0 ||
        data.url.length === 0 ||
        data.purpose.length === 0
      ) {
        toast.error("すべての項目を入力してください");
      }
      if (!reg.test(data.password)) {
        toast.error(
          "大文字・小文字・数字すべて使用した8文字以上のパスワードを設定してください"
        );
      }
    }
  });

  return (
    <div>
      {isConfirm ? (
        <ConfirmCodeForm email={email} password={password} />
      ) : (
        <>
          <form onSubmit={onSubmit}>
            <div className="form-items">
              <p>メールアドレス</p>
              <Input
                label=""
                placeholder="メールアドレス"
                type="email"
                id="email"
                {...register("email")}
              />
            </div>
            <div className="form-items">
              <p>パスワード</p>
              <Input
                label=""
                placeholder="パスワード"
                type="password"
                id="password"
                {...register("password")}
              />
              <div className="requirement">
                <p>
                  ※大文字・小文字・数字すべて使用した8文字以上のパスワードを設定してください。
                </p>
              </div>
            </div>

            <div className="form-items">
              <p>会社名</p>
              <Input
                label=""
                placeholder="例)株式会社AEGIS GROUP"
                type={"text"}
                id="company"
                {...register("company")}
              />
            </div>
            <div className="form-items">
              <p>担当者名</p>
              <Input
                label=""
                placeholder="担当者名（漢字）"
                type={"text"}
                id="name"
                {...register("name")}
              />
            </div>
            <div className="form-items">
              <p>担当者名（フリガナ）</p>
              <Input
                label=""
                placeholder="担当者名（フリガナ）"
                type={"text"}
                id="yomi"
                {...register("yomi")}
              />
            </div>
            <div className="form-items">
              <p>部署</p>
              <Input
                label=""
                placeholder="部署"
                type={"text"}
                id="department"
                {...register("department")}
              />
            </div>
            <div className="form-items">
              <p>役職</p>
              <Input
                label=""
                placeholder="役職"
                type={"text"}
                id="position"
                {...register("position")}
              />
            </div>
            <div className="form-items">
              <p>電話番号</p>
              <Input
                label=""
                placeholder="電話番号"
                type={"text"}
                id="phone"
                {...register("phone")}
              />
            </div>
            <div className="form-items">
              <p>会社URL</p>
              <Input
                label=""
                placeholder="会社URL"
                type={"text"}
                id="url"
                {...register("url")}
              />
            </div>
            <div className="form-items">
              <p>業務形態</p>
              <Select
                label=""
                placeholder="選択してください"
                {...register("business")}
              >
                <option value="">選択してください</option>
                <option value="農業，林業">農業，林業</option>
                <option value="漁業">漁業</option>
                <option value="鉱業，採石業，砂利採取業">
                  鉱業，採石業，砂利採取業
                </option>
                <option value="建設業">建設業</option>
                <option value="製造業">製造業</option>
                <option value="電気・ガス・熱供給・水道業">
                  電気・ガス・熱供給・水道業
                </option>
                <option value="情報通信業">情報通信業</option>
                <option value="運輸業，郵便業">運輸業，郵便業</option>
                <option value="卸売業，小売業">卸売業，小売業</option>
                <option value="金融業，保険業">金融業，保険業</option>
                <option value="不動産業，物品賃貸業">
                  不動産業，物品賃貸業
                </option>
                <option value="学術研究，専門・技術サービス業">
                  学術研究，専門・技術サービス業
                </option>
                <option value="宿泊業，飲食サービス業">
                  宿泊業，飲食サービス業
                </option>
                <option value="生活関連サービス業，娯楽業">
                  生活関連サービス業，娯楽業
                </option>
                <option value="教育，学習支援業">教育，学習支援業</option>
                <option value="医療，福祉">医療，福祉</option>
                <option value="複合サービス事業">複合サービス事業</option>
                <option value="サービス業">サービス業</option>
                <option value="公務">公務</option>
                <option value="その他">その他</option>
              </Select>
            </div>
            <div className="form-items">
              <p>登録目的</p>
              <Select
                label=""
                placeholder="選択してください"
                {...register("purpose")}
              >
                <option value="">選択してください</option>

                <option value="自社利用">自社利用</option>
                <option value="代理店として利用">代理店として利用</option>
                <option value="その他">その他</option>
              </Select>
            </div>
            <div className="button-wapper">
              <Button FullWidht type="submit">
                登録する
              </Button>
            </div>
          </form>
          <div className="footer">
            <p>
              アカウントをお持ちの方は
              <Link className="link" to={"/signin"}>
                こちら
              </Link>
            </p>
          </div>
        </>
      )}
    </div>
  );
};
