import React, { forwardRef } from "react";

import style from "./RefineButton.module.scss";

export type RefineButtonProps = {
  children: React.ReactNode;
  IsEnble?: boolean;
} & JSX.IntrinsicElements["button"];
export type Ref = HTMLButtonElement;

const RefineButton = forwardRef<Ref, RefineButtonProps>((props, ref) => {
  return (
    <button
      ref={ref}
      className={props.IsEnble ? style.true : style.false}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
});

RefineButton.displayName = "RefineButton";

export { RefineButton };
