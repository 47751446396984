import React, { forwardRef } from "react";

import style from "./Select.module.scss";

export type SelectProps = {
  label: string;
  placeholder: string;
  value?: string;
} & JSX.IntrinsicElements["select"];
export type Ref = HTMLSelectElement;

const Select = forwardRef<Ref, SelectProps>((props, ref) => {
  const { label, children, value, placeholder, ...otherProps } = props;
  return (
    <div>
      <label htmlFor={props.id}>{label}</label>

      <select
        ref={ref}
        className={style.Select}
        placeholder={placeholder}
        {...otherProps}
        value={value}
      >
        {children}
      </select>
    </div>
  );
});

Select.displayName = "Select";

export { Select };
