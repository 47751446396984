import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { TalentListType } from "../../type/types";
import style from "./CardTalent.module.scss";
export type CardTalentProps = {
  talent: TalentListType;
};

const CardTalent: React.FC<CardTalentProps> = (props) => {
  return (
    <Link to={"/talent/" + props.talent.id}>
      <div className={style.CardTalent}>
        <img
          className={style.img}
          src={process.env.REACT_APP_S3 + props.talent.profile_image}
        />
        <div className={style.buttom}>
          <p className={style.name}>{props.talent.name}</p>
          <p className={style.yomi}>{props.talent.kana}</p>
        </div>
      </div>
    </Link>
  );
};
CardTalent.displayName = "CardTalent";
export { CardTalent };
