import React, { useContext, useState, useEffect } from "react";
import { ModalContext } from "../../context/modalContext";
import { Button } from "../Elements/Button";
import { Input } from "../Elements/Input";
import { Select } from "../Elements/Select";
import style from "./talentSearchForm.module.scss";
import "./talentsearch.scss";
import { genderType, SortObject } from "../../type/types";
const p = [
  "",
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
  "海外",
  "その他",
  "非公開",
];
import "./talentsearch.scss";
type genreType = {
  id: string;
  title: string;
};

type SortPropsType = {
  genre: genreType[] | null;
  hobby: genreType[] | null;
  sortData: SortObject;
  setSort: any;
  freeword: string | null;
  setWord: any;
};

export const TalentSerchForm = (props: SortPropsType) => {
  const modal = useContext(ModalContext);
  const [selectedGenre, setSelectedGenre] = useState<string | null>("");
  const [gender, setGender] = useState<genderType>({
    m: true,
    f: true,
    o: true,
  });
  const [ageStart, setAgeStart] = useState<string | null>(null);
  const [ageEnd, setAgeEnd] = useState<string | null>(null);
  const [from, setFrom] = useState<string>("");
  const [selectedHobby, setSelectedHobby] = useState<string>("");
  const [costLow, setCostLow] = useState<number | null>(null);
  const [cost, setCost] = useState<number | null>(null);

  const changeGenre = (event: any) => {
    if (event.target.value.length !== 0) {
      setSelectedGenre(event.target.value);
    } else {
      setSelectedGenre(null);
    }
  };
  const clear = () => {
    props.setSort({
      selectedGenre: "",
      gender: { m: true, f: true, o: true },
      ageStart: null,
      ageEnd: null,
      selectedHobby: null,
      cost: null,
      from: null,
      costLow: null,
    });
    setSelectedGenre(null);
    setGender({ m: true, f: true, o: true });
    setAgeStart(null);
    setAgeEnd(null);
    setFrom("");
    setSelectedHobby("");
    setCost(null);
    setCostLow(null);
  };

  useEffect(() => {
    const sortTmp: genderType = { m: true, f: true, o: true };
    if (!props.sortData.gender.m) {
      sortTmp["m"] = false;
    }
    if (!props.sortData.gender.f) {
      sortTmp["f"] = false;
    }
    if (!props.sortData.gender.o) {
      sortTmp["o"] = false;
    }
    setGender(sortTmp);
    if (props.sortData.selectedGenre.length !== 0)
      setSelectedGenre(props.sortData.selectedGenre);
    if (props.sortData.ageStart !== null)
      setAgeStart(String(props.sortData.ageStart));
    if (props.sortData.ageEnd !== null)
      setAgeEnd(String(props.sortData.ageEnd));
    if (props.sortData.from !== null) setFrom(props.sortData.from);
    if (props.sortData.selectedHobby !== null)
      setSelectedHobby(props.sortData.selectedHobby);
    if (props.sortData.cost !== null) setCost(props.sortData.cost);
    if (props.sortData.costLow !== null) setCostLow(props.sortData.costLow);
  }, []);

  const changeGender = (event: any, genderType: number) => {
    if (genderType === 0) {
      setGender({
        m: !gender.m,
        f: gender.f,
        o: gender.o,
      });
    } else if (genderType === 1) {
      setGender({
        m: gender.m,
        f: !gender.f,
        o: gender.o,
      });
    } else {
      setGender({
        m: gender.m,
        f: gender.f,
        o: !gender.o,
      });
    }
  };

  const changeAge = (event: any, type: number) => {
    if (type === 0) {
      setAgeStart(event.target.value);
    } else {
      setAgeEnd(event.target.value);
    }
  };

  const changeCost = (event: any) => {
    if (!Number.isNaN(Number(event.target.value))) {
      setCost(event.target.value);
    } else {
      event.target.value = "";
    }
  };

  const changeLowCost = (event: any) => {
    if (!Number.isNaN(Number(event.target.value))) {
      setCostLow(event.target.value);
    } else {
      event.target.value = "";
    }
  };

  const search = () => {
    props.setWord(null);
    props.setSort({
      selectedGenre: selectedGenre,
      gender: gender,
      ageStart: ageStart,
      ageEnd: ageEnd,
      selectedHobby: selectedHobby,
      cost: cost,
      from: from,
      costLow: costLow,
    });
    modal.setIsOpen(false);
  };
  return (
    <div>
      <div>
        <div className="talnet-form-item">
          <p>著名人ジャンル</p>
          <Select
            label=""
            placeholder=" "
            onChange={(event: any) => {
              changeGenre(event);
            }}
            value={String(selectedGenre)}
          >
            <option value={""}></option>
            {props.genre?.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.title}
                </option>
              );
            })}
          </Select>
        </div>
        <div className="talnet-form-item">
          <p>性別</p>
          <div>
            <div className="checkboxRoot">
              <div className={"checkboxWrapper"}>
                <input
                  type={"checkbox"}
                  name={"gender"}
                  id={"gender.man"}
                  value="man"
                  className={"checkbox"}
                  onChange={(event: any) => {
                    changeGender(event, 0);
                  }}
                  checked={gender.m}
                />
                <label htmlFor={"gender.man"}>
                  <span className="text">男性</span>
                </label>
              </div>
              <div className={"checkboxWrapper"}>
                <input
                  type={"checkbox"}
                  name={"gender"}
                  id={"gender.woman"}
                  value="woman"
                  className={"checkbox"}
                  onChange={(event: any) => {
                    changeGender(event, 1);
                  }}
                  checked={gender.f}
                />
                <label htmlFor={"gender.woman"}>
                  <span className="text">女性</span>
                </label>
              </div>

              <div className={"checkboxWrapper"}>
                <input
                  type={"checkbox"}
                  name={"gender"}
                  id={"gender.other"}
                  value="other"
                  className={"checkbox"}
                  onChange={(event: any) => {
                    changeGender(event, 2);
                  }}
                  checked={gender.o}
                />
                <label htmlFor={"gender.other"}>
                  <span className="text">その他</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="talnet-form-item">
          <p>年齢</p>
          <div className="age">
            <div className="age-form">
              <Input
                placeholder=""
                label=""
                width="60px"
                onChange={(event: any) => {
                  changeAge(event, 0);
                }}
                value={ageStart === null ? "" : String(ageStart)}
              ></Input>
            </div>
            <span>〜</span>
            <div className="age-form">
              <Input
                placeholder=""
                width="60px"
                label=""
                onChange={(event: any) => {
                  changeAge(event, 1);
                }}
                value={ageEnd === null ? "" : String(ageEnd)}
              ></Input>
            </div>
          </div>
        </div>
        <div className="talnet-form-item">
          <p>出身・ゆかり</p>
          <Select
            label=""
            placeholder=" "
            onChange={(event: any) => {
              setFrom(event.target.value);
            }}
            value={String(from)}
          >
            {p.map((item) => {
              return (
                <option value={item} key={item}>
                  {item}
                </option>
              );
            })}
          </Select>
        </div>
        <div className="talnet-form-item">
          <p>資格・趣味・特技</p>
          <Select
            label=""
            placeholder=" "
            onChange={(event: any) => {
              setSelectedHobby(event.target.value);
            }}
            value={String(selectedHobby)}
          >
            <option value={""}></option>
            {props.hobby?.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.title}
                </option>
              );
            })}
          </Select>
        </div>
        <div className="talnet-form-item">
          <p>月額写真使用料（万円）</p>
          <div className="age">
            <div className="age-form">
              <Input
                label=""
                width="100px"
                placeholder={""}
                onChange={(event: any) => {
                  changeLowCost(event);
                }}
                value={costLow === null ? "" : String(costLow)}
              ></Input>
            </div>
            <span>〜</span>
            <div className="age-form">
              <Input
                label=""
                placeholder=""
                onChange={(event: any) => {
                  changeCost(event);
                }}
                value={cost === null ? "" : String(cost)}
              ></Input>
            </div>
          </div>
        </div>
      </div>
      <div className={style.buttons}>
        <button onClick={search} className="sortButton">
          検索
        </button>
        <button
          onClick={() => {
            clear();
            modal.setIsOpen(false);
          }}
          className="sortButton"
        >
          検索解除
        </button>
        <button
          onClick={() => {
            modal.setIsOpen(false);
          }}
          className="sortButton"
        >
          閉じる
        </button>
      </div>
    </div>
  );
};
