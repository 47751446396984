import React, { forwardRef } from "react";
import { IconSlider } from "../Icon/IconSlider";

import style from "./RefimentButton.module.scss";

export type RefimentButtonProps = {
  children: React.ReactNode;
} & JSX.IntrinsicElements["button"];
export type Ref = HTMLButtonElement;

const RefimentButton = forwardRef<Ref, RefimentButtonProps>((props, ref) => {
  const { children, ...otherPorps } = props;
  return (
    <button ref={ref} className={style.RefimentButton} {...otherPorps}>
      <span className={style.iconSpan}>
        <IconSlider
          className={style.icon}
          display={"inline"}
          style={{ display: "inline" }}
        />
      </span>
      {children}
    </button>
  );
});

RefimentButton.displayName = "RefimentButton";

export { RefimentButton };
