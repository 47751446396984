export type InputProps = {
  placeholder: string;
  id?: string;
  label: string;
  value?: string;
} & JSX.IntrinsicElements["input"];
import style from "./Input.module.scss";
import React, { forwardRef, ReactNode } from "react";

export type Ref = HTMLInputElement;

const Input = forwardRef<Ref, InputProps>((props, ref) => {
  const { label, name, value, ...InputProps } = props;
  return (
    <>
      <label htmlFor={props.id}>{label}</label>
      <input
        ref={ref}
        className={style.Input}
        name={name}
        {...InputProps}
        value={value}
      >
        {props.children}
      </input>
    </>
  );
});
Input.displayName = "Input";
export { Input };
