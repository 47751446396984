import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { Button } from "../Elements/Button";
import { Input } from "../Elements/Input";
import { Link } from "react-router-dom";
import "./auth.scss";
type FormData = {
  email: string;
  password: string;
};
export const SignInForm = (props: {
  setConfim: any;
  setEmail: any;
  setPassword: any;
}) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const onSubmit = handleSubmit(async (data) => {
    const result = await auth.signIn(data.email, data.password);
    if (!result.status) {
      if (result.error.name === "UserNotConfirmedException") {
        props.setConfim(true);
        props.setEmail(data.email);
        props.setPassword(data.password);
      } else if (result.error.name === "NotAuthorizedException") {
        toast.error("メールアドレスまたはパスワードに誤りがあります");
      } else {
        toast.error("メールアドレスまたはパスワードに誤りがあります");
      }
    } else {
      if (result.review) {
        navigate("/review");
      } else {
        navigate("/");
      }
    }
  });
  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="form-items">
          <p>メールアドレス</p>
          <Input
            label=""
            id="email"
            placeholder="メールアドレス"
            type={"text"}
            {...register("email")}
          />
        </div>

        {/* <input  /> */}
        <div className="form-items">
          <p>パスワード</p>
          <Input
            id="password"
            label=""
            placeholder="パスワード"
            type={"password"}
            {...register("password")}
          />
        </div>
        <div className="button-wapper">
          <Button type="submit" FullWidht={true}>
            ログインする
          </Button>
        </div>
        <div className="forgetpassword">
          <Link to="/resetpassword">パスワードを忘れた場合</Link>
        </div>
      </form>
      <div className="footer">
        <p>
          アカウントをお持ちでない方は
          <Link className="link" to={"/signup"}>
            こちら
          </Link>
        </p>
      </div>
    </div>
  );
};
