import React from "react";
import { TalentList } from "../component/Organisms/TalentList";

export const TalentListPage: React.FC = () => {
  return (
    <div>
      <TalentList />
    </div>
  );
};
