import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IconClose } from "../Icon/IconClose";
import { IconHamburgerMenu } from "../Icon/IconHunburgerMenu";
import style from "./Drawer.module.scss";
export type DrawerProps = { children: React.ReactNode };

const Drawer: React.FC<DrawerProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleExit = () => {
    setIsOpen(false);
  };

  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <div className={style.wrapper}>
      <button onClick={() => setIsOpen(true)}>
        <IconHamburgerMenu />
      </button>
      {isOpen && (
        <div className={style.drawer}>
          <button className={style.button} onClick={handleExit}>
            <IconClose width={"30px"} height={"30px"} />
          </button>
          <div className={style.content}>{props.children}</div>
        </div>
      )}
    </div>
  );
};
Drawer.displayName = "Drawer";
export { Drawer };
