import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { Button } from "../Elements/Button";
import { Input } from "../Elements/Input";
type FormData = {
  code: string;
};
export const ConfirmCodeForm = (props: { email: string; password: string }) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const onSubmit = handleSubmit(async (data) => {
    const result = await auth.confirmSignUp(props.email, data.code);
    if (result) {
      // 成功
      await auth.signIn(props.email, props.password);
      navigate("/review");
    } else {
      toast.error("認証コードに誤りがあります");
    }
    console.log({ data });
  });

  const resend = async () => {
    toast.success("再送しました");
    await auth.resendConfirmationCode(props.email);
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="form-items">
          <p>認証コード</p>
          <Input
            label=""
            placeholder="認証コード"
            type={"text"}
            id="code"
            {...register("code")}
          />
        </div>
        <div className="button-wapper">
          <Button FullWidht type="submit">
            認証
          </Button>
        </div>
      </form>
      <div className="button-wapper resend">
        <Button
          onClick={() => {
            resend();
          }}
          FullWidht
        >
          認証コードを再送
        </Button>
      </div>
    </div>
  );
};
