import React from "react";
import buttonStyle from "./LinkButton.module.scss";
export type ButtonProps = {
  children: React.ReactNode;
  /* solidだと埋めたスタイルに
   * outlineでは外側に線がある
   */
  variant?: "solid" | "outline";
  fullWidth?: boolean;
} & JSX.IntrinsicElements["a"];

export const LinkButton: React.FC<ButtonProps> = (props) => {
  const { variant = "solid", children, ...Anchorprops } = props;
  const ButtonClass =
    variant === "solid" ? buttonStyle.buttonSolid : buttonStyle.buttonOutline;
  return (
    <a
      className={`${ButtonClass} ${props.fullWidth && buttonStyle.fullWidth}`}
      {...Anchorprops}
    >
      {children}
    </a>
  );
};
