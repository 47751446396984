import React from "react";
import {
  AiFillTwitterSquare,
  AiFillInstagram,
  AiFillFacebook,
  AiFillYoutube,
} from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { MdWeb } from "react-icons/md";
export type SnsIconProps = {
  type: string | "twitter" | "instagram" | "facebook";
} & JSX.IntrinsicElements["svg"];

const SnsIcon: React.FC<SnsIconProps> = (props) => {
  const { type, ...otherProps } = props;
  switch (type) {
    case "Twitter":
      return <AiFillTwitterSquare {...otherProps} />;
    case "Instagram":
      return <AiFillInstagram {...otherProps} />;
    case "Facebook":
      return <AiFillFacebook {...otherProps} />;
    case "YouTube":
      return <AiFillYoutube {...otherProps} />;
    case "Tiktok":
      return <FaTiktok {...otherProps} />;
    default:
      return <MdWeb {...otherProps} />;
  }
};
SnsIcon.displayName = "SnsIcon";
export { SnsIcon };
