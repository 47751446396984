import React from "react";
import style from "./TalentInfoTable.module.scss";
export type TalentInfoTableProps = {
  tableList: Array<{ title: string; body: string }>;
};

const TalentInfoTable: React.FC<TalentInfoTableProps> = (props) => {
  return (
    <div className={style.TalentInfoTable}>
      <table className={style.table}>
        <tbody>
          {props.tableList.map((value) => (
            <tr key={value.title}>
              <td className={style.title}>
                {" "}
                <svg
                  style={{ display: "inline", padding: "1px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="長方形_212"
                        data-name="長方形 212"
                        width="18"
                        height="17"
                        transform="translate(685 535)"
                        fill="#fff"
                        stroke="#707070"
                        strokeWidth="1"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="マスクグループ_33"
                    data-name="マスクグループ 33"
                    transform="translate(-685 -535)"
                    clipPath="url(#clip-path)"
                  >
                    <path
                      id="__TEMP__SVG__"
                      d="M22.377,28.689a1.211,1.211,0,0,1-.933-1.987l5.428-6.494L21.638,13.7a1.239,1.239,0,1,1,1.951-1.527l5.852,7.27a1.212,1.212,0,0,1,0,1.539l-6.058,7.27a1.212,1.212,0,0,1-1.006.436Z"
                      transform="translate(668.318 523.309)"
                      fill="#2182c3"
                    />
                  </g>
                </svg>
                {value.title}
              </td>
              <td>
                {value.body !== "undefined" ? value.body : "非公開"}
                {value.title === "月額使用料" && "万円"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
TalentInfoTable.displayName = "TalentInfoTable";
export { TalentInfoTable };
