import React from "react";

export type IconHamburgerMenuProps = JSX.IntrinsicElements["svg"];

export const IconHamburgerMenu: React.FC<IconHamburgerMenuProps> = (props) => {
  return (
    <svg
      id="hamburger-menu"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="27.273"
      viewBox="0 0 30 27.273"
      {...props}
    >
      <path
        id="パス_1332"
        data-name="パス 1332"
        d="M5.625,6.75h30V9h-30Z"
        transform="translate(-5.625 -6.75)"
      />
      <path
        id="パス_1333"
        data-name="パス 1333"
        d="M5.625,16.875h30v2.25h-30Z"
        transform="translate(-5.625 -4.364)"
      />
      <path
        id="パス_1334"
        data-name="パス 1334"
        d="M5.625,27h30v2.25h-30Z"
        transform="translate(-5.625 -1.977)"
      />
    </svg>
  );
};
