import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { Button } from "../Elements/Button";
import { Drawer } from "../Elements/Drawer";
import { LinkButton } from "../Elements/LinkButton";

import NavBarStyle from "./NavBar.module.scss";
import logo from "../..//image/logo.svg";
export const NavBar: React.FC = () => {
  const auth = useContext(AuthContext);

  const navigate = useNavigate();

  return (
    <nav className={NavBarStyle.navBar}>
      <Link className={NavBarStyle.navLeft} to={"/"}>
        <div
          onClick={() => {
            navigate("/");
          }}
        >
          <img className={NavBarStyle.logo} src={logo} alt="TalentNavi" />
        </div>
      </Link>

      <div className={NavBarStyle.space} />
      {auth.isLogin ? (
        <div>
          <div className={NavBarStyle.rightButtons}>
            <LinkButton
              onClick={() => {
                navigate("/mypage");
              }}
            >
              マイページ
            </LinkButton>
            <Button onClick={() => auth.signOut()} variant="outline">
              ログアウト
            </Button>
          </div>
          <div className={NavBarStyle.drawer}>
            <Drawer>
              <div className={NavBarStyle.DrawerContet}>
                <LinkButton
                  onClick={() => {
                    navigate("/mypage");
                  }}
                >
                  マイページ
                </LinkButton>
                <Button onClick={() => auth.signOut()} variant="outline">
                  ログアウト
                </Button>
              </div>
            </Drawer>
            {/* <button onClick={() => console.log("クリックした")}> */}
            {/*   <IconHamburgerMenu /> */}
            {/* </button> */}
          </div>
        </div>
      ) : (
        <div>
          <div className={NavBarStyle.rightButtons}>
            <LinkButton
              onClick={() => {
                navigate("/signup");
              }}
            >
              新規登録
            </LinkButton>
            <Button
              onClick={() => {
                navigate("/signin");
              }}
              variant="outline"
            >
              ログイン
            </Button>
          </div>
          <div className={NavBarStyle.drawer}>
            <Drawer>
              <div className={NavBarStyle.DrawerContet}>
                <LinkButton
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  新規登録
                </LinkButton>
                <Button
                  onClick={() => {
                    navigate("/signin");
                  }}
                  variant="outline"
                >
                  ログイン
                </Button>
              </div>
            </Drawer>
            {/* <button onClick={() => console.log("クリックした")}> */}
            {/*   <IconHamburgerMenu /> */}
            {/* </button> */}
          </div>
        </div>
      )}
    </nav>
  );
};
