import React, { useContext, useEffect, useState } from "react";
import { Input } from "../component/Elements/Input";
import { Button } from "../component/Elements/Button";
import { Select } from "../component/Elements/Select";
import { AuthContext } from "../context/authContext";
import { toast } from "react-hot-toast";
import "./mypage.scss";

function MyPage() {
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");

  const [name, setName] = useState<string>("");
  const [yomi, setYomi] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [department, setDepartment] = useState<string>("");
  const [position, setPosition] = useState<string>("");
  const [purpose, setPurpose] = useState<string>("");

  const [email, setEmail] = useState<string>("");
  const [emailVerify, setEmailVerify] = useState<boolean>(true);

  const [code, setCode] = useState<string>("");

  const auth = useContext(AuthContext);
  const reg = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[!-~]{8,100}/;
  const submit = async () => {
    if (oldPassword.length !== 0 && reg.test(newPassword)) {
      const result = await auth.changePassword(oldPassword, newPassword);
      if (result.status) {
        setNewPassword("");
        setOldPassword("");
        toast.success("変更しました");
      } else {
        toast.error("失敗しました");
      }
    } else {
      toast.error(
        "大文字・小文字・数字をすべて使用した8文字以上のパスワードを入力してください"
      );
    }
  };

  useEffect(() => {
    (async () => {
      const result = await auth.userInfo();
      console.log(result.info.attributes);
      setName(result.info.attributes["custom:name"]);
      setYomi(result.info.attributes["custom:yomi"]);
      setPhoneNumber(result.info.attributes["custom:phone"]);
      setType(result.info.attributes["custom:business"]);
      setDepartment(result.info.attributes["custom:department"]);
      setPosition(result.info.attributes["custom:position"]);
      setPurpose(result.info.attributes["custom:purpose"]);
      setEmail(result.info.attributes["email"]);
      setEmailVerify(result.info.attributes["email_verified"]);
    })();
  }, []);

  const changeEmail = async () => {
    const result = await auth.updateEmail(email);
    if (result.status) {
      toast.success("確認メールを送信しました");
      setEmailVerify(false);
    } else {
      toast.error("失敗しました");
    }
  };

  const verifyEmail = async () => {
    const result = await auth.verifyChangeEmail(code);
    if (result.status) {
      location.reload();
    } else {
      toast.error("確認コードに誤りがあります");
    }
  };

  const editCompany = async () => {
    const result = await auth.updateUser(
      name,
      phoneNumber,
      type,
      department,
      position,
      yomi,
      purpose
    );
    if (result.status) {
      location.reload();
    } else {
      toast.error("失敗しました");
    }
  };

  return (
    <div className="mypage-wapper">
      <div className="mypage">
        <div className="head">
          <h1>パスワード変更</h1>
        </div>
        <div className="body">
          <div className="form-items">
            <p>古いパスワード</p>
            <Input
              label=""
              placeholder="今までのパスワードを入力してください"
              type={"password"}
              value={oldPassword}
              onChange={(event: any) => {
                setOldPassword(event.target.value);
              }}
            ></Input>
          </div>
          <div className="form-items">
            <p>新しいパスワード</p>
            <Input
              label=""
              placeholder="新しいパスワードを入力してください"
              type={"password"}
              value={newPassword}
              onChange={(event: any) => {
                setNewPassword(event.target.value);
              }}
            ></Input>
          </div>
          <br />
          <div className="button-wapper">
            <Button
              onClick={() => {
                submit();
              }}
            >
              変更
            </Button>
          </div>
        </div>
      </div>
      <div className="mypage">
        <div className="head">
          <h1>会社情報変更</h1>
        </div>
        <div className="body">
          <div className="form-items">
            <p>担当者名</p>
            <Input
              label=""
              placeholder=""
              onChange={(event: any) => {
                setName(event.target.value);
              }}
              value={name}
            ></Input>
          </div>
          <div className="form-items">
            <p>担当者名読み</p>
            <Input
              label=""
              placeholder=""
              onChange={(event: any) => {
                setYomi(event.target.value);
              }}
              value={yomi}
            ></Input>
          </div>
          <div className="form-items">
            <p>電話番号</p>
            <Input
              label=""
              placeholder=""
              onChange={(event: any) => {
                setPhoneNumber(event.target.value);
              }}
              value={phoneNumber}
            ></Input>
          </div>
          <div className="form-items">
            <p>事業形態</p>
            <Select
              label=""
              placeholder="選択してください"
              value={type}
              onChange={(e) => {
                setType(e.target.value);
              }}
            >
              <option value="">選択してください</option>
              <option value="農業，林業">農業，林業</option>
              <option value="漁業">漁業</option>
              <option value="鉱業，採石業，砂利採取業">
                鉱業，採石業，砂利採取業
              </option>
              <option value="建設業">建設業</option>
              <option value="製造業">製造業</option>
              <option value="電気・ガス・熱供給・水道業">
                電気・ガス・熱供給・水道業
              </option>
              <option value="情報通信業">情報通信業</option>
              <option value="運輸業，郵便業">運輸業，郵便業</option>
              <option value="卸売業，小売業">卸売業，小売業</option>
              <option value="金融業，保険業">金融業，保険業</option>
              <option value="不動産業，物品賃貸業">不動産業，物品賃貸業</option>
              <option value="学術研究，専門・技術サービス業">
                学術研究，専門・技術サービス業
              </option>
              <option value="宿泊業，飲食サービス業">
                宿泊業，飲食サービス業
              </option>
              <option value="生活関連サービス業，娯楽業">
                生活関連サービス業，娯楽業
              </option>
              <option value="教育，学習支援業">教育，学習支援業</option>
              <option value="医療，福祉">医療，福祉</option>
              <option value="複合サービス事業">複合サービス事業</option>
              <option value="サービス業">サービス業</option>
              <option value="公務">公務</option>
              <option value="その他">その他</option>
            </Select>
          </div>
          <div className="form-items">
            <p>部署</p>
            <Input
              label=""
              placeholder=""
              onChange={(event: any) => {
                setDepartment(event.target.value);
              }}
              value={department}
            ></Input>
          </div>
          <div className="form-items">
            <p>役職</p>
            <Input
              label=""
              placeholder=""
              onChange={(event: any) => {
                setPosition(event.target.value);
              }}
              value={position}
            ></Input>
          </div>

          <div className="form-items">
            <p>登録目的</p>
            <Select
              label=""
              placeholder="選択してください"
              value={purpose}
              onChange={(e) => {
                setPurpose(e.target.value);
              }}
            >
              <option value="">選択してください</option>

              <option value="自社利用">自社利用</option>
              <option value="代理店として利用">代理店として利用</option>
              <option value="その他">その他</option>
            </Select>
          </div>
          <br />
          <div className="button-wapper">
            <Button
              onClick={() => {
                editCompany();
              }}
            >
              変更
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyPage;
