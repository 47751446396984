import React from "react";
import Style from "./Button.module.scss";
export type ButtonProps = {
  FullWidht?: boolean;
  variant?: "solid" | "outline";
} & JSX.IntrinsicElements["button"];

type Ref = HTMLButtonElement;
const Button = React.forwardRef<Ref, ButtonProps>((props, ref) => {
  const { variant = "solid", FullWidht, ...otherProps } = props;
  return (
    <button
      className={`${FullWidht && Style.fullWidth} ${
        variant === "solid" ? Style.buttonSolid : Style.buttonOutline
      }`}
      ref={ref}
      {...otherProps}
    />
  );
});
Button.displayName = "Button";
export { Button };
