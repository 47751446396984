import React from "react";
import { Footer } from "./Footer";
import layoutStyle from "./layout.module.scss";
import { NavBar } from "./navBar";
export type LayoutProps = { children: React.ReactNode };

export const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <div className={layoutStyle.Layout}>
      <NavBar />
      <main className={layoutStyle.main}>{props.children}</main>
      <Footer />
    </div>
  );
};
