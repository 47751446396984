import React, { useEffect, useState, useContext } from "react";
import { Input } from "../component/Elements/Input";
import { Button } from "../component/Elements/Button";
import { Select } from "../component/Elements/Select";
import "./contact.scss";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { LoadingContext } from "../context/loading";
import { TalentInfo } from "../type/types";
import { toast } from "react-hot-toast";

function Contact() {
  const { talentid } = useParams();

  const auth = useContext(AuthContext);
  const loading = useContext(LoadingContext);

  const navigate = useNavigate();

  const [talent, setTalent] = useState<TalentInfo>();
  const [type, setType] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [url, setURL] = useState<string>("");
  const [purpose, setPurpose] = useState<{
    company: boolean;
    service: boolean;
    other: boolean;
  }>({
    company: false,
    service: false,
    other: false,
  });
  const [purposeDetail, setpurposeDetail] = useState<string>("");
  const [period, setPeriod] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [other, setOther] = useState<string>("");
  const [body, setBody] = useState<string>("");
  const [agree, setAgree] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [send, setSendStatus] = useState<boolean>(false);

  const [type2, setType2] = useState<string>("");

  useEffect(() => {
    (async () => {
      try {
        loading.setLoading(true);
        const token = await auth.getToken();
        const result = await axios.get(
          String(process.env.REACT_APP_API_ENDPOINT) + "talent",
          {
            params: {
              id: talentid,
            },
            headers: {
              Authorization: token.token,
            },
          }
        );
        setTalent(result.data[0]);
        loading.setLoading(false);
      } catch (error) {
        loading.setLoading(false);
        navigate("/404");
      }
    })();
  }, []);

  const check = () => {
    let other = false;
    let status = false;
    let purposeString = "";
    if (purpose.company) {
      status = true;
      purposeString += "企業プロモーション,";
    }
    if (purpose.service) {
      status = true;
      purposeString += "サービスプロモーション,";
    }
    if (purpose.other) {
      status = true;
      other = true;
    }
    if (other) {
      if (purposeDetail.length === 0) {
        status = false;
      } else {
        purposeString += purposeDetail;
      }
    }
    return {
      status,
      string: purposeString,
    };
  };
  interface Obj {
    [prop: string]: any;
  }

  const checkI = () => {
    if (type2 === "継続依頼") {
      if (period.length !== 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const submit = async () => {
    if (type === "サブスク利用") {
      if (agree) {
        if (
          type.length !== 0 &&
          companyName.length !== 0 &&
          url.length !== 0 &&
          period.length !== 0 &&
          price.length !== 0 &&
          check().status
        ) {
          const params: Obj = {
            type,
            companyName,
            url,
            purpose: check().string,
            period,
            price,
            talentId: talentid,
            talentName: talent?.name,
          };
          if (other.length !== 0) {
            params.talent = other;
          }
          if (body.length !== 0) {
            params.body = body;
          }
          try {
            const token = await auth.getToken();
            loading.setLoading(true);
            const result = await axios.post(
              String(process.env.REACT_APP_API_ENDPOINT) +
                "contact/subscription",
              params,
              {
                headers: {
                  Authorization: token.token,
                },
              }
            );
            setId(result.data.id);
            setSendStatus(true);
            loading.setLoading(false);
          } catch (error) {
            alert("エラーが発生しました");
            loading.setLoading(false);
          }
        } else {
          toast.error("必須項目を入力してください");
        }
      } else {
        toast.error("利用規約に同意してください");
      }
    } else {
      if (agree) {
        if (
          companyName.length !== 0 &&
          url.length !== 0 &&
          price.length !== 0 &&
          purposeDetail.length !== 0 &&
          type2.length !== 0 &&
          checkI()
        ) {
          const params: Obj = {
            type,
            companyName,
            url,
            price,
            detail: purposeDetail,
            type2,
            talentId: talentid,
            talentName: talent?.name,
          };
          if (period.length !== 0) {
            params["period"] = period;
          } else {
            params["period"] = "";
          }
          if (other.length !== 0) {
            params.talent = other;
          }
          if (body.length !== 0) {
            params.body = body;
          }
          try {
            const token = await auth.getToken();
            loading.setLoading(true);
            const result = await axios.post(
              String(process.env.REACT_APP_API_ENDPOINT) + "contact/individual",
              params,
              {
                headers: {
                  Authorization: token.token,
                },
              }
            );
            setId(result.data.id);
            setSendStatus(true);
            loading.setLoading(false);
          } catch (error) {
            alert("エラーが発生しました");
            loading.setLoading(false);
          }
        } else {
          toast.error("必須項目を入力してください");
        }
      } else {
        toast.error("利用規約に同意してください");
      }
    }
  };

  return (
    <div className="contact">
      <div className="header">
        <div>
          <h1>お問い合わせ</h1>
          <h2>Contact</h2>
        </div>
      </div>
      <div className="body">
        <div className="content">
          {!send ? (
            <div>
              <p className="bold">タレント名：{talent?.name}</p>
              <div className="form-item">
                <p className="bold sm">
                  案件種別<span>*</span>
                </p>
                <div
                  onChange={(event: any) => {
                    setType(event.target.value);
                  }}
                >
                  <div className="radio-wapper">
                    <label className="label" htmlFor="subscription">
                      <input
                        type="radio"
                        name="type"
                        value={"サブスク利用"}
                        id="subscription"
                      />
                      <span className="text">サブスク利用</span>
                    </label>
                    <br />
                    <label className="label" htmlFor="p">
                      <input
                        type="radio"
                        value={"個別案件"}
                        name="type"
                        id="p"
                      />
                      <span className="text">個別案件</span>
                    </label>
                  </div>
                </div>
              </div>
              {type !== "個別案件" ? (
                <div>
                  <div className="form-item">
                    <p className="bold sm">
                      企業名<span>*</span>
                    </p>
                    <Input
                      placeholder="タレントを利用する企業名を入力してください"
                      label=""
                      onChange={(event: any) => {
                        setCompanyName(event.target.value);
                      }}
                    ></Input>
                  </div>
                  <div className="form-item">
                    <p className="bold sm">
                      URL<span>*</span>
                    </p>
                    <Input
                      placeholder="HPまたは商品のURLを入力してください"
                      label=""
                      onChange={(event: any) => {
                        setURL(event.target.value);
                      }}
                    ></Input>
                  </div>
                  <div className="form-item">
                    <p className="bold sm">
                      使用目的<span>*</span>
                    </p>
                    <div className="">
                      <div className={"checkBoxWrapper"}>
                        <input
                          type={"checkbox"}
                          value={"企業プロモーション"}
                          id="company"
                          className={"checkbox"}
                          onClick={() => {
                            const p = Object.assign({}, purpose);
                            p.company = !p.company;
                            setPurpose(p);
                          }}
                        ></input>
                        <label htmlFor={"company"}>
                          <span className="text">企業プロモーション</span>
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <div className={"checkBoxWrapper"}>
                        <input
                          type={"checkbox"}
                          value={"サービスプロモーション"}
                          id="service"
                          className={"checkbox"}
                          onClick={() => {
                            const p = Object.assign({}, purpose);
                            p.service = !p.service;
                            setPurpose(p);
                          }}
                        ></input>
                        <label htmlFor={"service"}>
                          <span className="text">サービスプロモーション</span>
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <div className={"checkBoxWrapper"}>
                        <input
                          type={"checkbox"}
                          value={"その他"}
                          id="other"
                          className={"checkbox"}
                          onClick={() => {
                            const p = Object.assign({}, purpose);
                            p.other = !p.other;
                            setPurpose(p);
                          }}
                        ></input>

                        <label htmlFor={"other"}>
                          <span className="text">その他</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {purpose.other && (
                    <div className="form-item">
                      <p className="bold sm">
                        案件詳細<span>*</span>
                      </p>
                      <textarea
                        name=""
                        id=""
                        placeholder="依頼内容を入力してください"
                        onChange={(event: any) => {
                          setpurposeDetail(event.target.value);
                        }}
                      ></textarea>
                    </div>
                  )}

                  <div className="form-item">
                    <p className="bold sm">
                      使用期間<span>*</span>
                    </p>
                    <div
                      onChange={(event: any) => {
                        setPeriod(event.target.value);
                      }}
                    >
                      <div className="radio-wapper">
                        <label className="label" htmlFor="three">
                          <input
                            type="radio"
                            name="period"
                            id="three"
                            value={"3ヶ月"}
                          />
                          <span className="text">3ヶ月</span>
                        </label>
                        <label className="label" htmlFor="six">
                          <input
                            type="radio"
                            name="period"
                            id="six"
                            value={"6ヶ月"}
                          />
                          <span className="text">6ヶ月</span>
                        </label>
                        <label className="label" htmlFor="twelve">
                          <input
                            type="radio"
                            name="period"
                            id="twelve"
                            value={"12ヶ月"}
                          />
                          <span className="text">12ヶ月</span>
                        </label>
                        <label className="label" htmlFor="not">
                          <input
                            type="radio"
                            name="period"
                            id="not"
                            value={"未定"}
                          />
                          <span className="text">未定</span>
                        </label>
                      </div>
                      <br />
                    </div>
                  </div>
                  <div className="form-item">
                    <p className="bold sm">
                      ご予算<span>*</span>
                    </p>

                    <Input
                      placeholder="ご予算を入力してください"
                      label=""
                      onChange={(event: any) => {
                        setPrice(event.target.value);
                      }}
                    ></Input>
                  </div>
                  <div></div>
                  <div className="form-item">
                    <p className="bold sm">その他気になるタレント</p>
                    <Input
                      placeholder="タレント名を入力してください"
                      label=""
                      onChange={(event: any) => {
                        setOther(event.target.value);
                      }}
                    ></Input>
                  </div>
                  <div className="form-item">
                    <p className="bold sm">その他お問い合わせ</p>
                    <textarea
                      name=""
                      id=""
                      placeholder=""
                      onChange={(event: any) => {
                        setBody(event.target.value);
                      }}
                    ></textarea>
                  </div>
                  <div className="form-item">
                    <div className={"checkBoxWrapper"}>
                      <input
                        type="checkbox"
                        id="law"
                        className="checkbox"
                        onChange={() => {
                          setAgree(!agree);
                        }}
                      />
                      <label htmlFor={"law"}>
                        <span className="text">利用規約に同意します</span>
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="form-item">
                    <p className="bold sm">
                      企業名<span>*</span>
                    </p>
                    <Input
                      placeholder="タレントを利用する企業名を入力してください"
                      label=""
                      onChange={(event: any) => {
                        setCompanyName(event.target.value);
                      }}
                    ></Input>
                  </div>
                  <div className="form-item">
                    <p className="bold sm">
                      URL<span>*</span>
                    </p>
                    <Input
                      placeholder="HPまたは商品のURLを入力してください"
                      label=""
                      onChange={(event: any) => {
                        setURL(event.target.value);
                      }}
                    ></Input>
                  </div>

                  <div className="form-item">
                    <p className="bold sm">
                      案件種類<span>*</span>
                    </p>
                    <div
                      onChange={(e: any) => {
                        setType2(e.target.value);
                      }}
                    >
                      <div className="radio-wapper">
                        <label className="label" htmlFor="type2i">
                          <input
                            type="radio"
                            name="type2"
                            id="type2i"
                            value={"単発依頼"}
                          />
                          <span className="text">単発依頼</span>
                        </label>
                        <label className="label" htmlFor="type2c">
                          <input
                            type="radio"
                            name="type2"
                            id="type2c"
                            value={"継続依頼"}
                          />
                          <span className="text">継続依頼</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {type2 === "単発依頼" && (
                    <div>
                      <div className="form-item">
                        <p className="bold sm">
                          ご予算<span>*</span>
                        </p>

                        <Input
                          placeholder="ご予算を入力してください"
                          label=""
                          onChange={(event: any) => {
                            setPrice(event.target.value);
                          }}
                        ></Input>
                      </div>
                      <div className="form-item">
                        <p className="bold sm">
                          依頼詳細（※この内容をもとにタレントに確認いたしますので詳細にご記入ください。）
                          <span>*</span>
                        </p>
                        <textarea
                          name=""
                          id=""
                          placeholder=""
                          onChange={(event: any) => {
                            setpurposeDetail(event.target.value);
                          }}
                        ></textarea>
                      </div>
                    </div>
                  )}
                  {type2 === "継続依頼" && (
                    <div>
                      <div className="form-item">
                        <p className="bold sm">
                          使用期間<span>*</span>
                        </p>
                        <div
                          onChange={(event: any) => {
                            setPeriod(event.target.value);
                          }}
                        >
                          <div className="radio-wapper">
                            <label className="label" htmlFor="three">
                              <input
                                type="radio"
                                name="period"
                                id="three"
                                value={"3ヶ月"}
                              />
                              <span className="text">3ヶ月</span>
                            </label>
                            <label className="label" htmlFor="six">
                              <input
                                type="radio"
                                name="period"
                                id="six"
                                value={"6ヶ月"}
                              />
                              <span className="text">6ヶ月</span>
                            </label>
                            <label className="label" htmlFor="twelve">
                              <input
                                type="radio"
                                name="period"
                                id="twelve"
                                value={"12ヶ月"}
                              />
                              <span className="text">12ヶ月</span>
                            </label>
                            <label className="label" htmlFor="not">
                              <input
                                type="radio"
                                name="period"
                                id="not"
                                value={"未定"}
                              />
                              <span className="text">未定</span>
                            </label>
                          </div>
                          <br />
                        </div>
                      </div>
                      <div className="form-item">
                        <p className="bold sm">
                          ご予算<span>*</span>
                        </p>

                        <Input
                          placeholder="ご予算を入力してください"
                          label=""
                          onChange={(event: any) => {
                            setPrice(event.target.value);
                          }}
                        ></Input>
                      </div>
                      <div className="form-item">
                        <p className="bold sm">
                          依頼詳細（※この内容をもとにタレントに確認いたしますので詳細にご記入ください。）
                          <span>*</span>
                        </p>
                        <textarea
                          name=""
                          id=""
                          placeholder=""
                          onChange={(event: any) => {
                            setpurposeDetail(event.target.value);
                          }}
                        ></textarea>
                      </div>
                    </div>
                  )}
                  <div className="form-item">
                    <p className="bold sm">その他気になるタレント</p>
                    <Input
                      placeholder="タレント名を入力してください"
                      label=""
                      onChange={(event: any) => {
                        setOther(event.target.value);
                      }}
                    ></Input>
                  </div>
                  <div className="form-item">
                    <p className="bold sm">その他お問い合わせ</p>
                    <textarea
                      name=""
                      id=""
                      placeholder=""
                      onChange={(event: any) => {
                        setBody(event.target.value);
                      }}
                    ></textarea>
                  </div>
                  <div className="form-item">
                    <div className={"checkBoxWrapper"}>
                      <input
                        type="checkbox"
                        id="law"
                        className="checkbox"
                        onChange={() => {
                          setAgree(!agree);
                        }}
                      />
                      <label htmlFor={"law"}>
                        <span className="text">利用規約に同意します</span>
                      </label>
                    </div>
                  </div>
                </div>
              )}
              <br />
              <Button
                FullWidht={true}
                onClick={() => {
                  submit();
                }}
              >
                送信
              </Button>
            </div>
          ) : (
            <div className="statusTrue">
              <br />
              <p className="bold">お問い合わせを受け付けました</p>
              <br />
              <p>
                担当より折り返しご連絡させて頂きますのでしばらくお待ちください。
              </p>
              <br />
              <br />
              <p>タレント名：{talent?.name}</p>
              <br />
              <p>受付ID：{id}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Contact;
