import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { TalentInfo } from "../../type/types";
import { Button } from "../Elements/Button";
import { SnsIcon } from "../Elements/SnsIcon";
import { TalentInfoTable } from "../Elements/TalentInfoTable";
import style from "./TalentInfoCard.module.scss";

export type TalentInfoProps = {
  talentInfo: TalentInfo | undefined;
  buttonClick?: () => void;
};

const TalentInfoCard: React.FC<TalentInfoProps> = (props) => {
  const [hobby, setHobby] = useState("");
  const [birthday, setBirthday] = useState("");
  const [option, setOption] = useState("");
  useEffect(() => {
    console.log(props.talentInfo?.talent_specialties);
    setHobby("");
    let tmp = "";
    props.talentInfo?.talent_specialties?.forEach((item) => {
      tmp = tmp + "," + item.specialty.title;
    });
    tmp = tmp.slice(1);
    setHobby(tmp);
    let optionTmp = "";
    props.talentInfo?.talent_options.forEach((item) => {
      optionTmp = optionTmp + "," + item.optionText;
    });
    optionTmp = optionTmp.slice(1);
    setOption(optionTmp);
    if (props.talentInfo?.birthday === "0000-00-00") {
      setBirthday("非公開");
    } else {
      console.log(String(props.talentInfo?.birthday).split("-"));
      setBirthday(
        String(
          String(props.talentInfo?.birthday).split("-")[0] +
            "年" +
            String(props.talentInfo?.birthday).split("-")[1] +
            "月" +
            String(props.talentInfo?.birthday).split("-")[2] +
            "日"
        )
      );
    }
  }, [props]);
  return (
    <section className={style.TalentInfoCard}>
      <div className={style.head}>
        <h1>TALENT</h1>
        <h2>タレント詳細</h2>
      </div>
      <div className={style.outline}>
        <div className={style.content}>
          <div className={style.img_box}>
            <img
              alt={"タレントの写真"}
              className={style.TalentImg}
              src={
                String(process.env.REACT_APP_S3) +
                props.talentInfo?.profile_image
              }
            />
          </div>
          <div className={style.box}>
            <div>
              <p className={style.name}>{props.talentInfo?.name}</p>
              <p className={style.yomi}>{props.talentInfo?.kana}</p>
            </div>
            <div className={style.title}>
              {props.talentInfo?.talent_genres?.map((value, index) => (
                <div key={index} className={style.titleTag}>
                  <span>{value.genre.title}</span>
                </div>
              ))}
            </div>
            <div className={style.snsicons}>
              {props.talentInfo?.socialLinks?.map((value, index) => (
                <a key={index} href={value.url} className={style.snsicon}>
                  <SnsIcon
                    type={value.sns_type}
                    width={"1.5em"}
                    height={"1.5em"}
                  />
                </a>
              ))}
            </div>
            <TalentInfoTable
              tableList={[
                {
                  body: birthday,
                  title: "生年月日",
                },
                {
                  title: "月額使用料",
                  body: `${props.talentInfo?.price_min} 〜 ${props.talentInfo?.price_max}`,
                },
                {
                  title: "出身地",
                  body: props.talentInfo?.birthplace || "非公開",
                },
                {
                  title: "趣味・特技・資格",
                  body: hobby,
                },
                {
                  title: "オプション",
                  body: option,
                },
              ]}
            />
            <div className={style.profile}>
              <h1>プロフィール</h1>
              <p className={style.profileBody}>{props.talentInfo?.profile}</p>
            </div>
          </div>
        </div>
        <div className={style.buttonWapperWapper}>
          <div className={style.buttonWapper}>
            <Button
              FullWidht
              onClick={() => props.buttonClick && props.buttonClick()}
            >
              {"このタレントでお問い合わせする　〉"}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
TalentInfoCard.displayName = "TalentInfo";
export { TalentInfoCard };
