import React, { useContext, useEffect } from "react";
import {
  AuthContext,
  AuthContextProvider,
  AuthRouter,
} from "./context/authContext";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Layout } from "./component/layout/Layout";
import { SignUpPage } from "./routes/SignUpPage";
import { SignInPage } from "./routes/SignInPage";
import { TalentListPage } from "./routes/TalentListPage";
import { TalentInfoPage } from "./routes/TalentInfoPage";
import Review from "./routes/review";
import { Toaster } from "react-hot-toast";
import { LoadingContextProvider } from "./context/loading";
import { ModalContextProvider } from "./context/modalContext";
import Contact from "./routes/contact";
import PasswordReset from "./routes/passwordReset";
import MyPage from "./routes/mypage";
import NotFound from "./routes/404";

function SignOut() {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      await auth.signOut();
      navigate("/signin");
    })();
  }, []);
  return <></>;
}

function App() {
  return (
    <div className="App">
      <LoadingContextProvider>
        <AuthContextProvider>
          <BrowserRouter>
            <ModalContextProvider>
              <Layout>
                <Routes>
                  {/*ログイン画面*/}
                  <Route
                    path="/signin"
                    element={
                      <AuthRouter type={0}>
                        <SignInPage />
                      </AuthRouter>
                    }
                  ></Route>
                  {/*登録画面*/}
                  <Route
                    path="/signup"
                    element={
                      <AuthRouter type={0}>
                        <SignUpPage />
                      </AuthRouter>
                    }
                  ></Route>
                  {/*パスワードリセット*/}
                  <Route
                    path="/resetpassword"
                    element={
                      <AuthRouter type={0}>
                        <PasswordReset></PasswordReset>
                      </AuthRouter>
                    }
                  ></Route>
                  {/*ログアウト*/}
                  <Route path="/signout" element={<SignOut />}></Route>
                  {/*ホーム画面*/}
                  <Route
                    path="/"
                    element={
                      <AuthRouter type={2}>
                        <TalentListPage />
                      </AuthRouter>
                    }
                  ></Route>
                  {/*マイページ*/}
                  <Route
                    path="/mypage"
                    element={
                      <AuthRouter type={1}>
                        <MyPage></MyPage>
                      </AuthRouter>
                    }
                  ></Route>
                  {/*タレント詳細ページ*/}
                  <Route
                    path="/talent/:talentid"
                    element={
                      <AuthRouter type={1}>
                        <TalentInfoPage />
                      </AuthRouter>
                    }
                  ></Route>
                  {/*タレントお問い合わせページ*/}
                  <Route
                    path="/talent/:talentid/contact"
                    element={
                      <AuthRouter type={1}>
                        <Contact></Contact>
                      </AuthRouter>
                    }
                  />
                  <Route path="/review" element={<Review></Review>} />
                  {/*404*/}
                  <Route path="/404" element={<NotFound></NotFound>} />
                  <Route path="*" element={<NotFound></NotFound>} />
                </Routes>
              </Layout>
              <Toaster
                position="top-right"
                reverseOrder={false}
                toastOptions={{ style: { fontSize: "14px" } }}
              />
            </ModalContextProvider>
          </BrowserRouter>
        </AuthContextProvider>
      </LoadingContextProvider>
    </div>
  );
}

export default App;
