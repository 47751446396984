import React, { useContext, useState } from "react";
import "./passwordReset.scss";
import { Button } from "../component/Elements/Button";
import { Input } from "../component/Elements/Input";
import { AuthContext } from "../context/authContext";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "./auth.scss";

function PasswordReset() {
  const [page, setPage] = useState<boolean>(true);
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const auth = useContext(AuthContext);

  const sendCode = async () => {
    if (email.length !== 0) {
      const result = await auth.foregetPassword(email);
      if (result) {
        toast.success("送信しました");
        setPage(false);
      } else {
        toast.error("失敗しました");
      }
    }
  };
  const reg = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[!-~]{8,100}/;
  const navigate = useNavigate();

  const submitPassword = async () => {
    if (code.length !== 0) {
      if (reg.test(password)) {
        const result = await auth.foregetPasswordSubmit(email, code, password);
        if (result.status) {
          navigate("/signin");
        }
      } else {
        toast.error(
          "大文字・小文字・数字をすべて使用した8文字以上のパスワードを設定してください"
        );
      }
    }
  };

  return (
    <div className="authRoot">
      <div className="header">
        <h1>パスワードリセット</h1>
      </div>
      <div className="body">
        {page ? (
          <div>
            <div className="form-items">
              <p>メールアドレス</p>
              <Input
                label=""
                placeholder="メールアドレスを入力してください"
                onChange={(event: any) => {
                  setEmail(event.target.value);
                }}
              ></Input>
            </div>
            <div className="button-wapper">
              <Button
                FullWidht={true}
                onClick={() => {
                  sendCode();
                }}
              >
                確認コードを送信
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div className="form-items">
              <p>確認コード</p>
              <Input
                label=""
                placeholder="確認コードを入力してください"
                onChange={(event: any) => {
                  setCode(event.target.value);
                }}
                value={code}
              ></Input>
            </div>
            <div className="form-items">
              <p>新しいパスワード</p>

              <Input
                label=""
                type={"password"}
                placeholder="新しいパスワードを入力してください"
                onChange={(event: any) => {
                  setPassword(event.target.value);
                }}
              ></Input>
            </div>
            <div className="button-wapper">
              <Button
                FullWidht={true}
                onClick={() => {
                  submitPassword();
                }}
              >
                設定
              </Button>
            </div>
            <div className="button-wapper">
              <Button
                FullWidht={true}
                onClick={() => {
                  sendCode();
                }}
              >
                再送
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PasswordReset;
