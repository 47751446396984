import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TalentImgs } from "../component/Organisms/TalentImgs";
import { TalentInfoCard } from "../component/Organisms/TalentInfoCard";
import style from "./TalentListPage.module.scss";
import axios from "axios";
import { AuthContext } from "../context/authContext";
import { LoadingContext } from "../context/loading";
import { TalentInfo } from "../type/types";
import AWS from "aws-sdk";
import { Auth } from "aws-amplify";
const TalentInfoPage: React.FC = () => {
  const { talentid } = useParams();

  const auth = useContext(AuthContext);
  const loading = useContext(LoadingContext);

  const [talent, setTalent] = useState<TalentInfo>();
  const [img, setImg] = useState<string[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        loading.setLoading(true);
        const token = await auth.getToken();
        const result = await axios.get(
          String(process.env.REACT_APP_API_ENDPOINT) + "talent",
          {
            params: {
              id: talentid,
            },
            headers: {
              Authorization: token.token,
            },
          }
        );
        setTalent(result.data[0]);
        const imageTmp = img.concat();
        // @ts-ignore
        const r = await Auth.currentCredentials();
        AWS.config.update({
          region: "ap-northeast-1",
          credentials: r,
          signatureVersion: "v4",
        });
        const s3 = new AWS.S3();
        await Promise.all(
          result.data[0].images.map(async (item: any) => {
            if (item.url.length !== 0) {
              const params = {
                Bucket: process.env.REACT_APP_AFTER_BUCKET_NAME,
                Key: item.url,
              };
              const url = await s3.getSignedUrl("getObject", params);
              imageTmp.push(url);
            }
          })
        );
        setImg(imageTmp);
        console.log(result.data);
        loading.setLoading(false);
      } catch (error) {
        loading.setLoading(false);
        navigate("/404");
      }
    })();
  }, []);

  return (
    <div className={style.cards}>
      <TalentInfoCard
        talentInfo={talent}
        buttonClick={() => {
          return navigate(`/talent/${talentid}/contact`);
        }}
      />
      <TalentImgs imgs={img} />
    </div>
  );
};
export { TalentInfoPage };
