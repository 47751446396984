import React from "react";
import style from "./TalentListText.module.scss";
// export type TalentListTextProps = {};

const TalentListText: React.FC = () => {
  return (
    <div className={style.TalentListText}>
      <p className={style.talent}>TALENT</p>
      <p className={style.list}>タレント一覧</p>
    </div>
  );
};
TalentListText.displayName = "TalentListText";
export { TalentListText };
