import React from "react";
import "./404.scss";

function NotFound() {
  return (
    <div className="root">
      <h1>404 Not Found</h1>
      <p>ページが見つかりませんでした</p>
      <br />
      <a href="/">ホームへ戻る</a>
    </div>
  );
}

export default NotFound;
