import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LinkButton } from "../component/Elements/LinkButton";
import { SignInForm } from "../component/Form/SignInForm";
import { ConfirmCodeForm } from "../component/Form/ConfirmCodeForm";
import "./auth.scss";
import { useSearchParams } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";

export const SignInPage: React.FC = () => {
  const [confimPage, setConfimPage] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const result = searchParams.get("signup");

    if (result === "true") {
      toast.success("アカウントの作成に成功しました。メールをご確認ください。");
    }
  }, []);

  return (
    <div className="authRoot">
      <div className="header">
        <h1>ログイン</h1>
      </div>
      <div className="body">
        {!confimPage ? (
          <SignInForm
            setConfim={setConfimPage}
            setEmail={setEmail}
            setPassword={setPassword}
          />
        ) : (
          <ConfirmCodeForm email={email} password={password} />
        )}
      </div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{ style: { fontSize: "14px" } }}
      />
    </div>
  );
};
