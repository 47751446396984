import React from "react";
import style from "./Footer.module.scss";

const Footer: React.FC = () => {
  return (
    <footer className={style.Footer}>
      <small className={style.small}>
        &copy;TalentNavi All Light Reserved.
      </small>
    </footer>
  );
};
Footer.displayName = "Footer";
export { Footer };
