import React, { useContext, useState, useEffect } from "react";
import { TalentListType } from "../../type/types";
import { CardTalent } from "../Elements/CardTalent";
import { TalentImgs } from "./TalentImgs";
import Style from "./TalentList.module.scss";
import { Button } from "../Elements/Button";
import { ModalContext } from "../../context/modalContext";
import { TalentSerchForm } from "../Form/TalentSerchForm";
import { genderType, SortObject } from "../../type/types";
import { LoadingContext } from "../../context/loading";
import { AuthContext } from "../../context/authContext";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { Input } from "../Elements/Input";
import { RefineButton } from "../Elements/RefineButton";
import { SearchBox } from "../Elements/SearchBox";
import { RefimentButton } from "../Elements/RefimentButton";
import { TalentListText } from "../Elements/TalentListText";

type genreType = {
  id: string;
  title: string;
  isDisplay: boolean;
};

const TalentList: React.FC = (props) => {
  const [searchParams] = useSearchParams();
  const modal = useContext(ModalContext);
  const loading = useContext(LoadingContext);
  const auth = useContext(AuthContext);

  const [genre, setGenre] = useState<genreType[] | null>(null);
  const [hobby, setHobby] = useState<genreType[] | null>(null);
  const [freeword, setFreeword] = useState<string | null>(null);
  const [word, setWord] = useState<string>("");
  const [sortData, setSort] = useState<SortObject>({
    selectedGenre: "",
    gender: { m: true, f: true, o: true },
    ageStart: null,
    ageEnd: null,
    selectedHobby: null,
    cost: null,
    from: null,
    costLow: null,
  });
  const [talent, setTalent] = useState<TalentListType[] | null>(null);

  const sort = () => {
    modal.setTitle("絞り込み");
    modal.setComponent(
      <TalentSerchForm
        genre={genre}
        hobby={hobby}
        sortData={sortData}
        freeword={freeword}
        setSort={setSort}
        setWord={setFreeword}
      />
    );
    modal.setIsOpen(true);
  };

  useEffect(() => {
    (async (): Promise<void> => {
      const token = await auth.getToken();
      loading.setLoading(true);
      const genreResult = await axios.get(
        String(process.env.REACT_APP_API_ENDPOINT) + "genre",
        {
          headers: {
            Authorization: token.token,
          },
        }
      );
      setGenre(genreResult.data);
      if (Object.keys(genreResult.data).length === 0) setGenre([]);
      const hobbyResult = await axios.get(
        String(process.env.REACT_APP_API_ENDPOINT) + "hobby",
        {
          headers: {
            Authorization: token.token,
          },
        }
      );
      setHobby(hobbyResult.data);
      if (Object.keys(hobbyResult.data).length === 0) setHobby([]);
    })();
  }, []);
  useEffect(() => {
    if (genre !== null && hobby !== null) {
      setSort({
        selectedGenre: "",
        gender: { m: true, f: true, o: true },
        ageStart: null,
        ageEnd: null,
        selectedHobby: null,
        cost: null,
        from: null,
        costLow: null,
      });
    }
  }, [genre, hobby]);

  useEffect(() => {
    if (freeword !== null) {
      loading.setLoading(true);
      (async () => {
        const token = await auth.getToken();
        const result = await axios.get<TalentListType[]>(
          String(process.env.REACT_APP_API_ENDPOINT) + "search",
          {
            params: {
              q: freeword,
            },
            headers: {
              Authorization: token.token,
            },
          }
        );
        setTalent(result.data);
        if (Object.keys(result.data).length === 0) setTalent([]);
        loading.setLoading(false);
      })();
    }
  }, [freeword]);

  useEffect(() => {
    (async () => {
      const token = await auth.getToken();
      const params: any = {};
      if (
        sortData.selectedGenre !== null &&
        sortData.selectedGenre.length !== 0
      ) {
        params["genre"] = sortData.selectedGenre;
      }
      params["m"] = sortData.gender.m;
      params["f"] = sortData.gender.f;
      params["o"] = sortData.gender.o;
      if (sortData.ageStart !== null && sortData.ageStart !== null) {
        if (String(sortData.ageStart).length !== 0) {
          params["ageStart"] = Number(sortData.ageStart);
        }
      }
      if (sortData.ageEnd !== null && sortData.ageEnd !== null) {
        if (String(sortData.ageEnd).length !== 0) {
          params["ageEnd"] = Number(sortData.ageEnd);
        }
      }
      if (sortData.from !== null && sortData.from.length !== 0) {
        params["from"] = sortData.from;
      }
      if (
        sortData.selectedHobby !== null &&
        sortData.selectedHobby.length !== 0
      ) {
        params["hobby"] = sortData.selectedHobby;
      }
      if (sortData.cost !== null) {
        params["cost"] = sortData.cost;
      }
      if (sortData.costLow !== null) {
        params["costLow"] = sortData.costLow;
      }
      const result = await axios.get(
        String(process.env.REACT_APP_API_ENDPOINT) + "sort",
        {
          params: params,
          headers: {
            Authorization: token.token,
          },
        }
      );
      setTalent(result.data);
      if (Object.keys(result.data).length === 0) setTalent([]);
      loading.setLoading(false);
    })();
  }, [sortData]);

  const changeGenre = (id: string) => {
    const sort = Object.assign({}, sortData);
    sort.selectedGenre = id;
    setSort(sort);
  };

  return (
    <div>
      <SearchBox
        placeholder="フリーワードまたはタレント名で検索"
        buttonText="検索"
        value={word}
        onChange={(e) => {
          setWord(e.target.value);
        }}
        ButtonClick={() => {
          setFreeword(word);
        }}
      />
      <TalentListText />
      <div className={Style.refineList}>
        <RefimentButton
          onClick={() => {
            sort();
          }}
        >
          絞り込む
        </RefimentButton>
        <RefineButton
          IsEnble={sortData.selectedGenre === ""}
          onClick={() => {
            changeGenre("");
          }}
        >
          すべて
        </RefineButton>
        {genre?.map((item) => {
          if (item.isDisplay) {
            return (
              <RefineButton
                key={item.id}
                IsEnble={sortData.selectedGenre === item.id}
                onClick={() => {
                  changeGenre(item.id);
                }}
              >
                {item.title}
              </RefineButton>
            );
          }
        })}
      </div>

      <div className={Style.TalentList}>
        {talent?.map((talent) => (
          <CardTalent talent={talent} key={talent.id} />
        ))}
      </div>
    </div>
  );
};
TalentList.displayName = "TalentList";
export { TalentList };
