import React from "react";
import "./review.scss";
function Review() {
  return (
    <div className="review">
      <h1>審査中</h1>
      <p>現在アカウントを審査中です。</p>
      <p>審査完了後ログインできるようになります。</p>
    </div>
  );
}

export default Review;
