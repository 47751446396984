import React, { useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import awsConfig from "../awsconfig";
import { Amplify, Auth } from "aws-amplify";
Amplify.configure({ Auth: awsConfig });

export const AuthContext = React.createContext<{
  isLogin: boolean;
  signIn: any;
  signup: any;
  signOut: any;
  getToken: any;
  foregetPassword: any;
  foregetPasswordSubmit: any;
  changePassword: any;
  userInfo: any;
  confirmSignUp: any;
  resendConfirmationCode: any;
  confimSignIn: any;
  getUserObject: any;
  updateUser: any;
  updateEmail: any;
  verifyChangeEmail: any;
}>({
  isLogin: false,
  signIn: null,
  signup: null,
  signOut: null,
  getToken: null,
  foregetPassword: null,
  foregetPasswordSubmit: null,
  changePassword: null,
  userInfo: null,
  confirmSignUp: null,
  resendConfirmationCode: null,
  confimSignIn: null,
  getUserObject: null,
  updateUser: null,
  updateEmail: null,
  verifyChangeEmail: null,
});

export const AuthContextProvider = (props: any) => {
  const [isLogin, setLogin] = React.useState(() => {
    const value =
      String(localStorage.getItem("isLogin")).toLocaleLowerCase() === "true";
    return value !== null ? value : false;
  });

  const signup = async (
    email: string,
    password: string,
    company: string,
    name: string,
    phone: string,
    business: string,
    department: string,
    position: string,
    yomi: string,
    url: string,
    purpose: string
  ): Promise<{ status: boolean; error: any }> => {
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email: email,
          "custom:company": company,
          "custom:name": name,
          "custom:phone": phone,
          "custom:business": business,
          "custom:department": department,
          "custom:position": position,
          "custom:yomi": yomi,
          "custom:url": url,
          "custom:purpose": purpose,
        },
      });
      return {
        status: true,
        error: null,
      };
    } catch (error) {
      return {
        status: false,
        error,
      };
    }
  };

  const getUserObject = async () => {
    try {
      const result = await Auth.currentAuthenticatedUser();
      return {
        status: true,
        result,
      };
    } catch (error) {
      return {
        status: false,
        error,
      };
    }
  };

  const updateUser = async (
    name: string,
    phone: string,
    business: string,
    department: string,
    position: string,
    yomi: string,
    purpose: string
  ) => {
    try {
      const userObject = await getUserObject();
      await Auth.updateUserAttributes(userObject.result, {
        "custom:name": name,
        "custom:phone": phone,
        "custom:business": business,
        "custom:department": department,
        "custom:position": position,
        "custom:yomi": yomi,
        "custom:purpose": purpose,
      });
      return {
        status: true,
        error: null,
      };
    } catch (error) {
      return {
        status: false,
        error,
      };
    }
  };

  const updateEmail = async (email: string) => {
    // try {
    //   const userObject = await getUserObject();
    //   await Auth.updateUserAttributes(userObject.result, {
    //     email: email,
    //   });
    //   return {
    //     status: true,
    //   };
    // } catch (error) {
    //   return {
    //     status: false,
    //     error,
    //   };
    // }
  };

  const verifyChangeEmail = async (code: string) => {
    // try {
    //   await Auth.verifyCurrentUserAttributeSubmit("email", code);
    //   return {
    //     status: true,
    //   };
    // } catch (error) {
    //   return {
    //     status: false,
    //     error,
    //   };
    // }
  };

  const confirmSignUp = async (
    email: string,
    code: string
  ): Promise<boolean> => {
    try {
      await Auth.confirmSignUp(email, code);
      return true;
    } catch (error) {
      return false;
    }
  };

  const resendConfirmationCode = async (email: string) => {
    try {
      await Auth.resendSignUp(email);
      return true;
    } catch (err) {
      return false;
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setLogin(false);
      return true;
    } catch (error) {
      return false;
    }
  };

  const signIn = async (email: string, password: string) => {
    try {
      await signOut();
      console.log("ok");
      const user = await Auth.signIn(email, password);
      console.log(user);
      if ("custom:status" in user.attributes) {
        if (user.attributes["custom:status"] !== "1") {
          await signOut();
          return {
            status: true,
            error: null,
            user,
            review: true,
          };
        } else {
          setLogin(true);
          return {
            status: true,
            error: null,
            user,
            review: false,
          };
        }
      } else {
        await signOut();
        return {
          status: true,
          error: null,
          user,
          review: true,
        };
      }
    } catch (error) {
      return {
        status: false,
        error,
        user: null,
      };
    }
  };

  const getToken = async () => {
    try {
      const result = await Auth.currentSession();
      return {
        token: result.getIdToken().getJwtToken(),
        status: true,
      };
    } catch (error) {
      setLogin(false);
      return {
        token: null,
        status: false,
      };
    }
  };
  const userInfo = async () => {
    try {
      const result = await Auth.currentUserInfo();
      return {
        status: true,
        info: result,
      };
    } catch (error) {
      return {
        status: false,
        info: null,
      };
    }
  };

  const foregetPassword = async (email: string) => {
    try {
      await Auth.forgotPassword(email);
      return true;
    } catch (error) {
      return false;
    }
  };
  const foregetPasswordSubmit = async (
    email: string,
    code: string,
    password: string
  ) => {
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      return {
        status: true,
        error: null,
      };
    } catch (error) {
      return {
        status: false,
        error,
      };
    }
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      return {
        status: true,
      };
    } catch (error) {
      return {
        status: false,
        error,
      };
    }
  };

  const confimSignIn = async (user: any, code: string) => {
    try {
      await Auth.confirmSignIn(user, code, "SOFTWARE_TOKEN_MFA");
      setLogin(true);
      return true;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    localStorage.setItem("isLogin", String(isLogin));
  }, [isLogin]);

  return (
    <AuthContext.Provider
      value={{
        isLogin,
        signup,
        signIn,
        signOut,
        getToken,
        foregetPassword,
        foregetPasswordSubmit,
        changePassword,
        userInfo,
        confirmSignUp,
        resendConfirmationCode,
        confimSignIn,
        getUserObject,
        updateUser,
        updateEmail,
        verifyChangeEmail,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const LoginAlert = () => {
  useEffect(() => {
    alert("会員登録が必要です。");
    location.href = "/signin";
  }, []);
  return <></>;
};

export const AuthRouter = (props: any) => {
  const auth = useContext(AuthContext);
  if (auth.isLogin) {
    if (props.type === 1) return props.children;
    if (props.type === 0) return <Navigate to="/"></Navigate>;
    if (props.type === 2) return props.children;
  } else {
    if (props.type === 1) return <LoginAlert></LoginAlert>;
    if (props.type === 0) return props.children;
    if (props.type === 2) return props.children;
  }
};

/*
  使い方
  useContext(authContext)
  でauthを生成
  
  新規登録
  auth.signup(メアド,パスワード)

  ログイン
  const result = auth.signIn(メアド,パスワード)
  メールアドレス認証が完了していない場合
  result.error.nameにUserNotConfirmedExceptionが入るので確認コードの入力フォームへと遷移
  認証コードを受け取ったら
  auth.confirmSignUp(メアド,コード)
  で認証
  再送は
  auth.resendConfirmationCode(メアド)

  パスワードリセット
  確認コードの発行
  auth.foregetPassword(メアド)
  変更
  auth.foregetPasswordSubmit(メアド,コード,パスワード)

  パスワード変更（リセットではない）
  ログイン状態で
  auth.changePassword(古いパスワード,新しいパスワード)
*/
